
import { Options, Vue } from 'vue-class-component';
import { ActivityDataRecorderClient, CompanyClient, Scope3Client } from '@/services/Services';
import ActivityDataResultModal from '../modals/activityDataResultModal.vue';
import AddNewPremisesModal from '../modals/addNewPremisesModal.vue';
import * as OM from '@/Model';
import * as VM from '@/viewModel';

@Options({
    components: { },
})
export default class Scope3ActivityRecorder extends Vue {

    viewModel = VM;

    //globals
    timeFrameList: string[] = [];
    monthList: OM.TextIdentifier[] = [];
    categories: string[] = [];
    premises: string[] = [];

    //transporation
    transportation_fuelEquipmentTypes: string[] = [];
    transportation_fuelNames: string[] = [];
    transportation_uoms: string[] = [];
    transportation_sources: string[] = [];

    //travel
    travel_JourneyModes: string[] = [];
    travel_FlightTypes: string[] = [];
    travel_PassengerTypes: string[] = [];
    travel_BySeaFerryPassengerTypes: string[] = [];
    travel_RadiactiveForcing: string[] = [];
    travel_uoms: string[] = [];
    travel_sources: string[] = [];
    travel_BySeaFerryUoms: string[] = [];
    travel_BySeaFerrySources: string[] = [];

    //fuel and energy
    fuelEnergy_fuelClassifications: string[] = [];
    fuelEnergy_fuelEquipmentTypes: string[] = [];
    fuelEnergy_fuelNames: string[] = [];
    fuelEnergy_uoms: string[] = [];
    fuelEnergy_sources: string[] = [];

    timeFrameSelected: string = "";
    scope3EmissionData: OM.Scope3EmissionData = new OM.Scope3EmissionData();
    saveModel: OM.ActivityDataRecorderScope3VM = new OM.ActivityDataRecorderScope3VM();
    loaded: boolean = false;

    created() {
        this.init();
    }

    init() {
        this.timeFrameList = Object.values(VM.TimeFrame);
        this.timeFrameSelected = VM.TimeFrame.Year;

        var monthKeys = Object.keys(VM.Months);
        for(var i = 0; i < monthKeys.length; i++) {
            this.monthList.push({
                identifier: i.toString(),
                text: monthKeys[i]
            })
        }

        Promise.all([
            Scope3Client.getCategories(),
            CompanyClient.getPremisesByCompany(),
        ])
        .then(xs => {
            this.categories = xs[0];
            this.premises = xs[1];

            this.scope3EmissionData.scope = VM.Scopes.Scope3;

            this.loaded = true;
        })
    }

    addPremises() {
        this.$opModal.show(AddNewPremisesModal, {
            callback: () => {
                this.$opModal.closeLast();
                this.init();
            }
        })
    }

    get isTransportationSelected() {
        return this.scope3EmissionData.category == 'Upstream transportation' || this.scope3EmissionData.category == 'Downstream transportation';
    }
    get isTravelSelected() {
        return this.scope3EmissionData.category == 'Business travel';
    }
    get isFuelAndEnergySelected() {
        return this.scope3EmissionData.category == 'Fuel and energy related activities';
    }

    categoryUpdated() {
        if(this.isTransportationSelected) {
            Promise.all([
                Scope3Client.getTransportationEquipmentTypes(),
                Scope3Client.getTransportationSource()
            ])
            .then(xs => {
                this.transportation_fuelEquipmentTypes = xs[0];
                this.transportation_sources = xs[1];
            })
        }
        else if(this.isTravelSelected) {
            Scope3Client.getJourneyModes()
            .then(x => {
                this.travel_JourneyModes = x;
            })
        }
        else if(this.isFuelAndEnergySelected) {
            Scope3Client.getFuelClassifications()
            .then(x => {
                this.fuelEnergy_fuelClassifications = x;
            })
        }
    }

    //transportation --------------------------------------------------------
    transportation_fuelEquipmentTypeUpdated() {
        Promise.all([
            Scope3Client.getTransportationFuelNames(this.scope3EmissionData.fuelEquipmentType),
            Scope3Client.getTransportationUnitOfMeasure(this.scope3EmissionData.fuelEquipmentType)
        ])
        .then(xs => {
            this.transportation_fuelNames = xs[0];
            this.transportation_uoms = xs[1];
        })
    }

    //travel ----------------------------------------------------------------
    get isByAirFlights() {
        return this.scope3EmissionData.journeyMode == 'By air flights';
    }
    get isBySeaFerry() {
        return this.scope3EmissionData.journeyMode == 'By sea ferry';
    }
    travel_journeyModeUpdated() {
        if(this.isByAirFlights) {
            Scope3Client.getFlightTypes()
            .then(x => {
                this.travel_FlightTypes = x;
            })
        }
        else if(this.isBySeaFerry) {
            Promise.all([
                Scope3Client.getSeaPassengerTypes(),
                Scope3Client.getSeaUnits(),
                Scope3Client.getSeaSources()
            ])
            .then(xs => {
                this.travel_BySeaFerryPassengerTypes = xs[0];
                this.travel_BySeaFerryUoms = xs[1];
                this.travel_BySeaFerrySources = xs[2];
            })
        }
    }
    travel_flightTypeUpdated() {
        Promise.all([
            Scope3Client.getFlightPassengerTypes(this.scope3EmissionData.flightType),
            Scope3Client.getFlightRadiativeForces(this.scope3EmissionData.flightType),
            Scope3Client.getFlightUnitOfMeasures(this.scope3EmissionData.flightType),
            Scope3Client.getFlightSources(this.scope3EmissionData.flightType)
        ])
        .then(xs => {
            this.travel_PassengerTypes = xs[0];
            this.travel_RadiactiveForcing = xs[1];
            this.travel_uoms = xs[2];
            this.travel_sources = xs[3];
        })
    }

    //fuel and energy --------------------------------------------------------
    fuelClassificationUpdated() {
        Promise.all([
            Scope3Client.getFuelEquipmentTypes(this.scope3EmissionData.fuelClassification),
            Scope3Client.getFuelAndEnergySources(this.scope3EmissionData.fuelClassification)
        ])
        .then(xs => {
            this.fuelEnergy_fuelEquipmentTypes = xs[0];
            this.fuelEnergy_sources = xs[1];
        })
    }
    fuelEnergy_EquipmentTypeUpdated() {
        Scope3Client.getFuelNames(this.scope3EmissionData.fuelClassification, this.scope3EmissionData.fuelEquipmentType)
        .then(x => {
            this.fuelEnergy_fuelNames = x;
        })
    }
    fuelEnergy_FuelNameUpdated() {
        Scope3Client.getFuelAndEnergyUnit(this.scope3EmissionData.fuelClassification, this.scope3EmissionData.fuelEquipmentType, this.scope3EmissionData.fuelName)
        .then(x => {
            this.fuelEnergy_uoms = x;
        })
    }

    get calculateDisabled() {
        var baseCheck = !this.saveModel.premisesType || !this.scope3EmissionData.category ||
            !this.saveModel.year || !this.saveModel.consumption || !this.scope3EmissionData.source;

        if(this.timeFrameSelected == VM.TimeFrame.Month)
            baseCheck = baseCheck || !this.saveModel.month;

        if(this.isTransportationSelected) {
            var transportationCheck = !this.scope3EmissionData.fuelEquipmentType || !this.scope3EmissionData.fuelName || !this.scope3EmissionData.uom;
            return baseCheck || transportationCheck;
        }
        else if(this.isTravelSelected) {
            if(this.isByAirFlights) {
                var airFlightCheck = !this.scope3EmissionData.flightType ||
                    !this.scope3EmissionData.radiativeForcing || !this.scope3EmissionData.uom;
                return baseCheck || airFlightCheck;
            }
            else if(this.isBySeaFerry) {
                return baseCheck || !this.scope3EmissionData.passengerType;
            }
            else
                return baseCheck;
        }
        else if(this.isFuelAndEnergySelected) {
            var fuelEnergyCheck = !this.scope3EmissionData.fuelClassification || !this.scope3EmissionData.fuelEquipmentType || 
                !this.scope3EmissionData.fuelName || !this.scope3EmissionData.uom;
            return baseCheck || fuelEnergyCheck;
        }
        else
            return baseCheck;
    }

    calculateAndSave() {
        this.saveModel.emissionDataBase = this.scope3EmissionData;

        if(this.isTransportationSelected) {
            Scope3Client.getTransportationEF(this.scope3EmissionData.fuelEquipmentType, this.scope3EmissionData.fuelName,
                this.scope3EmissionData.uom, this.scope3EmissionData.source)
                .then(x => {
                    this.assingEFValueAndSave(x);
                })
        }
        else if(this.isTravelSelected) {
            if(this.isByAirFlights) {
                Scope3Client.getAirTravelEF(this.scope3EmissionData.flightType, this.scope3EmissionData.source, 
                    this.scope3EmissionData.radiativeForcing, this.scope3EmissionData.uom)
                    .then(x => {
                        this.assingEFValueAndSave(x);
                    })
            }
            else if(this.isBySeaFerry) {
                Scope3Client.getSeaFerryEF(this.scope3EmissionData.passengerType, this.scope3EmissionData.source)
                .then(x => {
                    this.assingEFValueAndSave(x);
                })
            }
        }
        else if(this.isFuelAndEnergySelected) {
            Scope3Client.getFuelAndEnergyEf(this.scope3EmissionData.fuelClassification, this.scope3EmissionData.fuelEquipmentType, 
                this.scope3EmissionData.fuelName, this.scope3EmissionData.source, this.scope3EmissionData.uom)
                .then(x => {
                    this.assingEFValueAndSave(x);
                })
        }
    }

    assingEFValueAndSave(ef: number) {
        this.saveModel.emissionDataBase.ef = ef;

        if(this.timeFrameSelected == VM.TimeFrame.Month) {
            ActivityDataRecorderClient.saveScope3(this.saveModel)
            .then(() => {
                this.$opModal.show(ActivityDataResultModal, {
                    emissionValue: ef * this.saveModel.consumption
                })
            })
        }
        else {
            ActivityDataRecorderClient.saveScope3YearTimeFrame(this.saveModel)
            .then(() => {
                this.$opModal.show(ActivityDataResultModal, {
                    // emissionValue: (ef * this.saveModel.consumption) * 12
                    emissionValue: ef * this.saveModel.consumption
                })
            })
        }
    }

}
