
import { Options, Vue } from 'vue-class-component';
import Scope1ActivityRecorder from '../components/scope1ActivityRecorder.vue';
import Scope2ActivityRecorder from '../components/scope2ActivityRecorder.vue';
import Scope3ActivityRecorder from '../components/scope3ActivityRecorder.vue';
import * as VM from '@/viewModel';

@Options({
    components: { 
        Scope1ActivityRecorder,
        Scope2ActivityRecorder,
        Scope3ActivityRecorder
    },
})
export default class ActivityDataRecorder extends Vue {

    viewModel = VM;
    scopesList: string[] = Object.values(VM.Scopes);
    selectedScope: string = "";

}
