
import { Options, Vue } from 'vue-class-component';
import { ActivityDataRecorderClient, CompanyClient, Scope2Client } from '@/services/Services';
import ActivityDataResultModal from '../modals/activityDataResultModal.vue';
import AddNewPremisesModal from '../modals/addNewPremisesModal.vue';
import * as OM from '@/Model';
import * as VM from '@/viewModel';

@Options({
    components: { },
})
export default class Scope2ActivityRecorder extends Vue {

    viewModel = VM;
    selectedScope: string = VM.Scopes.Scope2;

    monthList: OM.TextIdentifier[] = [];

    categories: string[] = [];
    methodologies: string[] = [];
    premises: string[] = [];
    uoms: string[] = [];
    sources: string[] = [];
    countries: string[] = [];
    statesTerritories: string[] = [];

    scope2EmissionData: OM.Scope2EmissionData = new OM.Scope2EmissionData();
    saveModel: OM.ActivityDataRecorderScope2VM = new OM.ActivityDataRecorderScope2VM();
    loaded: boolean = false;

    created() {
        this.init();
    }

    init() {
        var monthKeys = Object.keys(VM.Months);
        for(var i = 0; i < monthKeys.length; i++) {
            this.monthList.push({
                identifier: i.toString(),
                text: monthKeys[i]
            })
        }

        Promise.all([
            CompanyClient.getPremisesByCompany(),
            Scope2Client.getCategories(),
            Scope2Client.getMethodologies(),
            Scope2Client.getCountries(),
            Scope2Client.getOUM(),
            Scope2Client.getStates(),
            Scope2Client.getSources()
        ])
        .then(xs => {
            this.premises = xs[0];
            this.categories = xs[1];
            this.methodologies = xs[2];
            this.countries = xs[3];
            this.uoms = xs[4];
            this.statesTerritories = xs[5];
            this.sources = xs[6];

            this.scope2EmissionData.scope = VM.Scopes.Scope2;

            this.loaded = true;
        })
    }

    addPremises() {
        this.$opModal.show(AddNewPremisesModal, {
            callback: () => {
                this.$opModal.closeLast();
                this.init();
            }
        })
    }

    get calculateDisabled() {
        var baseCheck = !this.saveModel.premisesType || !this.scope2EmissionData.category || !this.scope2EmissionData.approach ||
            !this.saveModel.year || !this.saveModel.month || !this.saveModel.consumption || !this.scope2EmissionData.uom;

        if(!this.scope2EmissionData.approach)
            return baseCheck;
        else if(this.scope2EmissionData.approach == 'Location-Based') {
            var locationCheck = !this.scope2EmissionData.country || !this.scope2EmissionData.stateTerritory || !this.scope2EmissionData.source;
            return baseCheck || locationCheck;
        }
        else if(this.scope2EmissionData.approach == 'Market-Based') {
            var marketCheck = !this.scope2EmissionData.supplierName || !this.scope2EmissionData.ef;
            return baseCheck || marketCheck;
        }
    }

    calculateAndSave() {
        this.saveModel.emissionDataBase = this.scope2EmissionData;
        
        if(this.scope2EmissionData.approach == 'Location-Based') {
            Scope2Client.getEF(this.scope2EmissionData.country, this.scope2EmissionData.source)
            .then(x => {
                this.saveModel.emissionDataBase.ef = x;

                ActivityDataRecorderClient.saveScope2(this.saveModel)
                .then(() => {
                    this.$opModal.show(ActivityDataResultModal, {
                        emissionValue: x * this.saveModel.consumption
                    })
                })
            })
        }
        else if (this.scope2EmissionData.approach == 'Market-Based') {
            ActivityDataRecorderClient.saveScope2(this.saveModel)
            .then(() => {
                this.$opModal.show(ActivityDataResultModal, {
                    emissionValue: this.scope2EmissionData.ef * this.saveModel.consumption
                })
            })
        }
    }

}
