
import { Options, Vue } from 'vue-class-component';
import { ActivityDataRecorderClient, CompanyClient, ElectricityEmissionClient, Scope1Client } from '@/services/Services';
import ActivityDataResultModal from '../modals/activityDataResultModal.vue';
import AddNewPremisesModal from '../modals/addNewPremisesModal.vue';
import * as OM from '@/Model';
import * as VM from '@/viewModel';

@Options({
    components: { },
})
export default class Scope1ActivityRecorder extends Vue {

    viewModel = VM;

    timeFrameList: string[] = [];
    monthList: OM.TextIdentifier[] = [];

    categories: string[] = [];
    fuelEquipmentTypes: string[] = [];
    fuelNames: string[] = [];
    premises: string[] = [];
    uoms: string[] = [];
    sources: string[] = [];
    // countries: OM.TextIdentifier[] = [];

    timeFrameSelected: string = "";
    scope1EmissionData: OM.Scope1EmissionData = new OM.Scope1EmissionData();
    saveModel: OM.ActivityDataRecorderScope1VM = new OM.ActivityDataRecorderScope1VM();
    loaded: boolean = false;

    created() {
        this.init();
    }

    init() {
        this.timeFrameList = Object.values(VM.TimeFrame);
        this.timeFrameSelected = VM.TimeFrame.Year;

        var monthKeys = Object.keys(VM.Months);
        for(var i = 0; i < monthKeys.length; i++) {
            this.monthList.push({
                identifier: i.toString(),
                text: monthKeys[i]
            })
        }

        Promise.all([
            Scope1Client.getCategories(),
            CompanyClient.getPremisesByCompany(),
            // ElectricityEmissionClient.getCountries(true)
        ])
        .then(xs => {
            this.categories = xs[0];
            this.premises = xs[1];
            // this.countries = xs[2];

            this.scope1EmissionData.scope = VM.Scopes.Scope1;

            this.loaded = true;
        })
    }

    addPremises() {
        this.$opModal.show(AddNewPremisesModal, {
            callback: () => {
                this.$opModal.closeLast();
                this.init();
            }
        })
    }

    categoryUpdated() {
        Promise.all([
            Scope1Client.getFuelCategories(this.scope1EmissionData.category),
            Scope1Client.getSources(this.scope1EmissionData.category)
        ])
        .then(xs => {
            this.fuelEquipmentTypes = xs[0];
            this.sources = xs[1];

            this.scope1EmissionData.fuelEquipmentType = "";
            this.scope1EmissionData.source = "";
        })
    }

    fuelEquipmentTypeUpdated() {
        Scope1Client.getFuelNames(this.scope1EmissionData.category, this.scope1EmissionData.fuelEquipmentType)
        .then(x => {
            this.fuelNames = x;

            this.scope1EmissionData.fuelName = "";
        })
    }

    fuelNameUpdated() {
        Scope1Client.getOUM(this.scope1EmissionData.category, this.scope1EmissionData.fuelEquipmentType, this.scope1EmissionData.fuelName)
        .then(x => {
            this.uoms = x;

            this.scope1EmissionData.uom = "";
        })
    }

    get calculateDisabled() {
        var baseCheck = !this.scope1EmissionData.category || !this.scope1EmissionData.fuelEquipmentType || 
            !this.scope1EmissionData.fuelName || !this.scope1EmissionData.source || !this.saveModel.year;

        if(this.timeFrameSelected == VM.TimeFrame.Year)
            return baseCheck;
        else 
            return baseCheck || !this.saveModel.month;
        // || !this.scope1EmissionData.country
    }

    calculateAndSave() {
        // this.scope1EmissionData.country
        Scope1Client.getEF(this.scope1EmissionData.category, this.scope1EmissionData.fuelEquipmentType, 
            this.scope1EmissionData.fuelName, this.scope1EmissionData.uom, this.scope1EmissionData.source)
        .then(x => {
            this.scope1EmissionData.ef = x;
            this.saveModel.emissionDataBase = this.scope1EmissionData;
    
            if(this.timeFrameSelected == VM.TimeFrame.Month) {
                ActivityDataRecorderClient.saveScope1(this.saveModel)
                .then(() => {
                    this.$opModal.show(ActivityDataResultModal, {
                        emissionValue: x * this.saveModel.consumption
                    })
                })
            }
            else {
                ActivityDataRecorderClient.saveScope1YearTimeFrame(this.saveModel)
                .then(() => {
                    this.$opModal.show(ActivityDataResultModal, {
                        // emissionValue: (x * this.saveModel.consumption) * 12
                        emissionValue: x * this.saveModel.consumption
                    })
                })
            }
        })
    }

}
