
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class ActivityDataResultModal extends Vue {

    @Prop() emissionValue: number;

    created() {}

    goToList() {
        this.$opModal.closeLast();
        this.$router.push('/data-inventory');
    }
    
}
